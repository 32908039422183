import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const InfoBanner = ({pathName, heading, copy, copy2, image1, image2, image3, white}) => {
    return (
        <Container fluid className={`pdrm-info-banner p-0 ${white && "white"}`} style={{backgroundImage: `url('${pathName}')`}}>
            <Row className={`py-5`}>
                <Row>
                    <Col xs={9} md={6}>
                        <Row>
                            <h1 className="pt-5">{heading}</h1>
                        </Row>
                        <Row>
                            <p>{copy}</p>
                            {copy2 && <p>{copy2}</p>}
                        </Row>
                        { image1 && <h3 className="pt-4 pt-xs-0 text-center">Before &amp; afters</h3> }
                    </Col>
                </Row>
              
           
            { image1 && 
             <Row className="pdrm-info-banner-row">
                <Col md={4}>
                    <img src={image1} height="300px" width="300px"></img>
                </Col>

                <Col md={4}>
                    <img src={image2} height="300px" width="300px"></img>
                </Col>

                <Col md={4}>
                    <img src={image3} height="300px" width="300px"></img>
                </Col>
             </Row>
            }
             </Row>
        </Container>
    )
};
export default InfoBanner;