import React from 'react';
import Layout from "../components/layout";
import {Container, Row, Col, Button} from 'react-bootstrap';
import BigDents from "../assets/icons/big-dents.svg";
import Hail from "../assets/icons/hail.svg";
import PDR from "../assets/icons/pdr.svg";
import Windshield from "../assets/icons/windshield.svg";
import Shine from "../assets/icons/shine.svg";
import Car from "../assets/icons/car.svg";
import Brush from "../assets/icons/brush.svg";
import Headlight from "../assets/icons/headlight.svg"
import InfoBanner from '../components/info-banner';
import bannerImage from '../assets/classic-banner-bg.jpg';
import ba1 from "../assets/before-after-blue.webp";
import ba2 from "../assets/before-after-red.webp";
import ba3 from "../assets/before-after-white.webp";
import Star from "../assets/star.svg";

import { SEO } from "../components/seo"


const PaintRepairs = () => {
    return (
        <Layout>
            <Container className="p-0 pdrm-sub__hero--wrapper">
                <Container className="pdrm-sub__hero p-0">
                    <Row className="pdrm-sub__hero__row g-0">
                        <Col lg={10} sm={12}>
                        <div className="pdrm-sub__hero__text-wrapper">
                            <img src={Star} width="30px" height="30px" alt="star" className="pdrm__hero-star"></img>
                            <img src={Star} width="30px" height="30px" alt="star" className="pdrm__hero-star"></img>
                            <img src={Star} width="30px" height="30px" alt="star" className="pdrm__hero-star"></img>
                            <h1>Paintless Dent Repair</h1>
                            <p>We provide a mobile paintless dent repair service, meaning you can have your dings, dents and scrapes fixed from the comfort of your home or workplace. Other repairs offered include plastic bumper dents, big dents, hail repairs, paint chip/scratch brush touch repair, buffing &amp; polishing, windscreen chips and headlight restoration.</p>
                            <Button href="tel:0407348941" className="pdrm-button">Get a quote</Button>  
                        </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="pdrm-pdr-info text-end" style={{backgroundImage: `url(${bannerImage})`, backgroundSize: 'cover', backgroundPosition: '50%', minHeight: '350px'}}>
                <Row style={{justifyContent: 'center', alignItems: 'center'}}>
                    <Col>
                        <h1>We offer onsite scratch and dent repair</h1>
                        <h5 className="text-end">Straight to your door, anywhere in New England</h5>
                        <h5>Call 0407 348 941 for a quote</h5>
                    </Col>
                </Row>
            </Container>

            <InfoBanner heading="What is Paintless Dent Repairs?" 
                        copy="Paintless dent repairs is a method of panel beating that requires a specific set of skills and tools to do correctly, but allows the vehicle to maintain original factory paint. Paintless dent repairs also saves time and is cheaper than traditional repairs."
                        image1={ba1}
                        image2={ba2}
                        image3={ba3}>
            </InfoBanner>

            <Container fluid className="pdrm-sub__banner--services">
                <Container fluid className="pdrm-sub__pros">
                    <Row>
                        <Col xs={12}>
                            <h1>Our Services</h1>
                        </Col>
                        <Col sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={PDR} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Paintless dent repairs</h3>
                            </div>
                        </Col>
                        <Col sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={Car} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Plastic bumper dents</h3>
                            </div>
                        </Col>
                        <Col sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={BigDents} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Big dents</h3>
                            </div>
                        </Col>
                        <Col  sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={Hail} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Hail repairs</h3>
                            </div>
                        </Col>

                        <Col sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={Brush} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Chip &amp; scratch brush repair</h3>
                            </div>
                        </Col>
                        <Col sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={Shine} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Buffing &amp; polishing</h3>
                            </div>
                        </Col>
                        <Col  sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={Windshield} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Windscreen chips</h3>
                            </div>
                        </Col>
                        <Col  sm={6} lg={3}>
                            <div className="pdrm-sub__pros--card grow">
                                <div className="pdrm-sub__pros--icon">
                                    <img src={Headlight} width="50px" alt="Tick"></img>
                                </div>
                                <h3>Headlight restoration</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
        </Container>
        </Layout>
    )
};
export default PaintRepairs;

export const Head = () => (
    <SEO />
  )